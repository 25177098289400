import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { parseParam } from './utils';
var listenOn = function listenOn(_ref) {
  var scopeName = _ref.scopeName,
    commandName = _ref.commandName,
    onMessage = _ref.onMessage;
  var handleMessageListener = function handleMessageListener(_ref2) {
    var data = _ref2.data;
    if (typeof data !== 'string') return;
    var _data$split = data.split(':'),
      _data$split2 = _slicedToArray(_data$split, 3),
      scope = _data$split2[0],
      command = _data$split2[1],
      params = _data$split2[2];
    if (scopeName !== scope) return;
    if (commandName !== command) return;
    var parsedParams = params ? params.split(',').map(parseParam) : [];
    console.info("[Native->Web] ".concat(scope, ":").concat(command, ":").concat(params));
    onMessage(parsedParams);
  };
  window.addEventListener('message', handleMessageListener, false);
  return function () {
    return window.removeEventListener('message', handleMessageListener, false);
  };
};
export default listenOn;