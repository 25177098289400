import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useEffect } from 'react';
import eventApi from '@motimate/event-api';
import { isProduction } from '@motimate/utils';
import Logger from '@ui/utils/logger';
import { useRouter } from 'next/router';
import { FormattedMessage, defineMessages } from 'react-intl';
import { p } from '@/lib/path-utils';
import { isAppUrl } from '@/lib/url-utils';
import UserAgent from '@/lib/user-agent';
import IconCloudClock from '@/icons/cloud-clock.svg';
import IconCloudRemove from '@/icons/cloud-remove.svg';
import IconFaceAstonished from '@/icons/face-astonished.svg';
import IconHexagon from '@/icons/hexagon.svg';
import IconWifiOff from '@/icons/wifi-off.svg';
import UIButton from '@/components/ui/button';
import UIIcon from '@/components/ui/icon';
import StyledErrorPage from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var apiURL = "".concat(String(process.env.API_PROTOCOL), "://").concat(String(process.env.API_HOST));
var statusTextMessages = defineMessages({
  403: {
    id: 'error.page.403.statusText'
  },
  404: {
    id: 'error.page.404.statusText'
  },
  "default": {
    id: 'error.page.default.statusText'
  },
  offline: {
    id: 'error.page.offline.statusText'
  },
  timeout: {
    id: 'error.page.timeout.statusText'
  },
  unavailable: {
    id: 'error.page.unavailable.statusText'
  }
});
var infoMessages = defineMessages({
  403: {
    id: 'error.page.403.info'
  },
  404: {
    id: 'error.page.404.info'
  },
  "default": {
    id: 'error.page.default.info'
  },
  offline: {
    id: 'error.page.offline.info'
  },
  timeout: {
    id: 'error.page.timeout.info'
  },
  unavailable: {
    id: 'error.page.unavailable.info'
  }
});
var _ref2 = true ? {
  name: "1qfth22",
  styles: "> :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(0.5rem * var(--tw-space-x-reverse));margin-left:calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));}"
} : {
  name: "1fq0sdo-ErrorPage",
  styles: "> :not([hidden]) ~ :not([hidden]){--tw-space-x-reverse:0;margin-right:calc(0.5rem * var(--tw-space-x-reverse));margin-left:calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));};label:ErrorPage;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var ErrorPage = function ErrorPage(_ref) {
  var _error$code, _error$response$statu, _error$response;
  var componentStack = _ref.componentStack,
    error = _ref.error,
    resetError = _ref.resetError;
  // @ts-ignore
  var code = (_error$code = error === null || error === void 0 ? void 0 : error.code) !== null && _error$code !== void 0 ? _error$code : null;
  // @ts-ignore
  var status = (_error$response$statu = error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status) !== null && _error$response$statu !== void 0 ? _error$response$statu : null;
  var message = error instanceof Error ? error.message : null;
  var isNetworkError = message === 'Network Error';
  var isOffline = !window.navigator.onLine && isNetworkError;
  var isTimeout = code === 'ECONNABORTED';
  var isUnavailable = window.navigator.onLine && isNetworkError;
  var router = useRouter();
  var getActionLabelId = function getActionLabelId() {
    if (status === 403) {
      if (isAppUrl(window.document.referrer)) {
        return 'dialog.action.back';
      }
      return 'error.page.homePage';
    }
    if (status === 404) {
      return 'error.page.homePage';
    }
    return 'retry';
  };
  var getType = function getType() {
    if (isOffline) {
      return 'offline';
    }
    if (isTimeout) {
      return 'timeout';
    }
    if (isUnavailable) {
      return 'unavailable';
    }
    if (status === 403) {
      return '403';
    }
    if (status === 404) {
      return '404';
    }
    return 'default';
  };
  var handleActionClick = function handleActionClick() {
    resetError();
    if (status === 403) {
      if (isAppUrl(document.referrer)) {
        router.back();
        return;
      }
      router.push(p('/'));
      return;
    }
    if (status === 404) {
      router.push(p('/'));
      return;
    }
    router.reload();
  };
  var handleFillReportClick = function handleFillReportClick() {
    Logger.report();
  };
  var isReportable = function isReportable() {
    if (isOffline) {
      return false;
    }
    if (isTimeout) {
      return false;
    }
    if (isUnavailable) {
      return false;
    }
    if (status) {
      return status >= 500;
    }
    return true;
  };
  var renderExtraInfo = function renderExtraInfo() {
    if (isUnavailable) {
      return null;
    }
    if (isProduction()) {
      return null;
    }
    return _jsxs("p", {
      children: ["Please check connection to ", _jsx("a", {
        href: apiURL,
        children: apiURL
      }), "."]
    });
  };
  var renderIcon = function renderIcon() {
    if (isOffline) {
      return _jsx(UIIcon, {
        as: IconWifiOff,
        color: "#d6dde6",
        size: 196
      });
    }
    if (isTimeout) {
      return _jsx(UIIcon, {
        as: IconCloudClock,
        color: "#d6dde6",
        size: 196
      });
    }
    if (isUnavailable) {
      return _jsx(UIIcon, {
        as: IconCloudRemove,
        color: "#d6dde6",
        size: 196
      });
    }
    if (status === 403 || status === 404) {
      return _jsxs(_Fragment, {
        children: [_jsx(UIIcon, {
          as: IconHexagon,
          color: "#d6dde6",
          size: 196
        }), _jsx("span", {
          children: status
        })]
      });
    }
    return _jsx(UIIcon, {
      as: IconFaceAstonished,
      color: "#d6dde6",
      size: 196
    });
  };
  useEffect(function () {
    if (UserAgent.isMobileApp()) {
      eventApi.render.completed();
    }
  }, []);
  return _jsxs(StyledErrorPage, {
    children: [_jsx("h1", {
      children: renderIcon()
    }), _jsx("h2", {
      children: _jsx(FormattedMessage, _objectSpread({}, statusTextMessages[getType()]))
    }), _jsx("p", {
      children: _jsx(FormattedMessage, _objectSpread({}, infoMessages[getType()]))
    }), !isProduction() && _jsxs("pre", {
      children: [message, _jsx("br", {}), _jsx("br", {}), componentStack]
    }), _jsx(_Fragment, {
      children: renderExtraInfo
    }), _jsx("br", {}), _jsxs("div", {
      css: _ref2,
      children: [_jsx(UIButton, {
        textTransform: "uppercase",
        variant: isReportable() ? 'secondary' : 'primary',
        onClick: handleActionClick,
        children: _jsx(FormattedMessage, {
          id: getActionLabelId()
        })
      }), isReportable() && _jsx(UIButton, {
        textTransform: "uppercase",
        variant: "primary",
        onClick: handleFillReportClick,
        children: _jsx(FormattedMessage, {
          id: "error.page.fillReport"
        })
      })]
    })]
  });
};
export default ErrorPage;