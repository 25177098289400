import { useEffect } from 'react';
import { ThemeProvider } from "@emotion/react";
import { useEventApi } from '@motimate/event-api';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/nextjs';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { setAutoFreeze } from 'immer';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useOrganizationQuery } from '@/lib/hooks/useOrganization';
import UserAgent from '@/lib/user-agent';
import { PageAuth } from '@/auth';
import { theme } from '@/theme';
import AppToaster from '@/components/app/AppToaster';
import ErrorBoundary from '@/components/app/error-boundary';
import FullScreenProvider from '@/training/components/full-screen-provider';
import { CloudinaryProvider } from '@ui/components/v2/cloudinary/CloudinaryContext';
import OrganizationFeatureFlagsCheck from './OrganizationFeatureFlagsCheck';
import StyledApp from './styled';
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
var isBrowser = true;
if (isBrowser) {
  setAutoFreeze(false);
}
var setMetaViewport = function setMetaViewport(isMobileApp) {
  var _metaViewportEl$getAt;
  var metaViewportEl = document.querySelector('meta[name="viewport"]');
  if (!metaViewportEl) return;
  var parameters = ((_metaViewportEl$getAt = metaViewportEl.getAttribute('content')) !== null && _metaViewportEl$getAt !== void 0 ? _metaViewportEl$getAt : '').split(',').map(function (v) {
    return v.trim();
  });
  if (isMobileApp) {
    parameters.push('user-scalable=no');
  }
  metaViewportEl.setAttribute('content', parameters.join(','));
};
function isPagePublic(router) {
  return router.route.startsWith('/[[...app]]') && !router.asPath.includes('/training/') || router.route.startsWith('/_error') || router.route.startsWith('/maintenance') || router.route.startsWith('/sentry') || router.route.startsWith('/signin') || router.route === '/[organization]' || router.route.startsWith('/[organization]/mfa') || router.route.startsWith('/[organization]/password') || router.route.startsWith('/[organization]/signin') || router.route.startsWith('/[organization]/signout') || router.route.startsWith('/[organization]/external-auth-completed');
}
var EmptyWrapper = function EmptyWrapper(_ref) {
  var children = _ref.children;
  return _jsx(_Fragment, {
    children: children
  });
};
var App = function App(_ref2) {
  var children = _ref2.children;
  var router = useRouter();
  var _useOrganizationQuery = useOrganizationQuery({
      redux: false
    }),
    organization = _useOrganizationQuery.data;
  var isMobileApp = UserAgent.isMobileApp();
  useEffect(function () {
    if (true) {
      setMetaViewport(isMobileApp);
    }
  }, []);
  useEventApi('app', 'ready', [], {
    enable: isMobileApp
  });
  var PageWrapper = isPagePublic(router) ? EmptyWrapper : PageAuth;
  return _jsxs(_Fragment, {
    children: [_jsx(Head, {
      children: _jsx("meta", {
        name: "viewport",
        content: "width=device-width, initial-scale=1"
      })
    }), process.env.NEXT_PUBLIC_CLOUDFLARE_WEB_ANALYTICS_TOKEN && _jsx(Script, {
      src: "https://static.cloudflareinsights.com/beacon.min.js",
      "data-cf-beacon": "{\"token\": \"".concat(process.env.NEXT_PUBLIC_CLOUDFLARE_WEB_ANALYTICS_TOKEN, "\"}")
    }), _jsx(AppToaster, {}), _jsx(ThemeProvider, {
      theme: theme,
      children: _jsx(FullScreenProvider, {
        children: _jsx(CloudinaryProvider, {
          cloudName: organization === null || organization === void 0 ? void 0 : organization.cloudinary_cloud_name,
          children: _jsx(StyledApp, {
            children: _jsx(SentryErrorBoundary, {
              fallback: ErrorBoundary,
              children: _jsxs(PageWrapper, {
                children: [_jsx(OrganizationFeatureFlagsCheck, {
                  children: children
                }), _jsx(ReactQueryDevtools, {
                  initialIsOpen: false
                })]
              })
            })
          })
        })
      })
    })]
  });
};
export default App;