import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { parseParam } from './utils';
var once = function once(scopeName, commandName) {
  var executor = function executor(resolve) {
    var onMessage = function onMessage(_ref) {
      var data = _ref.data;
      if (typeof data !== 'string') return;
      var _data$split = data.split(':'),
        _data$split2 = _slicedToArray(_data$split, 3),
        scope = _data$split2[0],
        command = _data$split2[1],
        params = _data$split2[2];
      if (scopeName !== scope) return;
      if (commandName !== command) return;
      window.removeEventListener('message', onMessage, false);
      if (params) {
        var parsedParams = params.split(',').map(parseParam);
        console.info("[Native->Web] ".concat(scope, ":").concat(command, ":").concat(params));
        resolve(parsedParams);
        return;
      }
      console.info("[Native->Web] ".concat(scope, ":").concat(command));
      resolve();
    };
    window.addEventListener('message', onMessage, false);
  };
  return new Promise(executor);
};
export default once;