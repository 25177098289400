import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Toast, Toaster, resolveValue, sendToast } from '@ui/components/toast';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var AppToaster = function AppToaster() {
  var _useIntl = useIntl(),
    formatMessage = _useIntl.formatMessage;
  var toastHeaders = useMemo(function () {
    return {
      error: formatMessage({
        id: 'toast.error.title'
      }),
      warning: formatMessage({
        id: 'toast.warning.title'
      }),
      success: formatMessage({
        id: 'toast.success.title'
      })
    };
  }, [formatMessage]);
  return _jsx(Toaster, {
    children: function children(hot) {
      var variant = 'warning';
      if (hot.type === 'error' || hot.type === 'success') {
        variant = hot.type;
      }
      return _jsx(Toast
      // @ts-ignore
      , {
        headerText: hot.headerText,
        variant: variant,
        variantHeaders: toastHeaders,
        visible: hot.visible,
        onClose: function onClose() {
          return sendToast.dismiss(hot.id);
        },
        children: resolveValue(hot.message, hot)
      });
    }
  });
};
export default AppToaster;