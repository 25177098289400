import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
export var mapKeysDeepToCamelCase = function mapKeysDeepToCamelCase(object) {
  return camelCaseKeys(object, {
    deep: true
  });
};
export var mapKeysDeepToSnakeCase = function mapKeysDeepToSnakeCase(object) {
  return snakeCaseKeys(object, {
    deep: true
  });
};
export var mapKeysToCamelCase = function mapKeysToCamelCase(object) {
  return camelCaseKeys(object, {
    deep: false
  });
};
export var mapKeysToSnakeCase = function mapKeysToSnakeCase(object) {
  return snakeCaseKeys(object, {
    deep: false
  });
};
// Same as `Object.entries()` but with type inference
export function objectEntries(obj) {
  return Object.entries(obj);
}

// Same as `Object.keys()` but with type inference
export function objectKeys(obj) {
  return Object.keys(obj);
}

// Same as `Object.values()` but with type inference
export function objectValues(obj) {
  return Object.values(obj);
}
export var sortBy = function sortBy(array, by, opts) {
  return array === null || array === void 0 ? void 0 : array.sort(function (a, b) {
    var nA = opts.isDate ? Number(new Date(a[by])) : a[by];
    var nB = opts.isDate ? Number(new Date(b[by])) : b[by];
    if (opts.direction === 'desc') {
      if (typeof nA === 'string' && typeof nB === 'string') {
        var sortResult = Intl.Collator().compare(nA, nB);
        return sortResult === 1 ? -1 : 1;
      }
      if (nA > nB) return -1;
      if (nA < nB) return 1;
      return 0;
    }
    if (typeof nA === 'string' && typeof nB === 'string') return Intl.Collator().compare(nA, nB);
    if (nA < nB) return -1;
    if (nA > nB) return 1;
    return 0;
  });
};