import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import eventApi from '@motimate/event-api';
import Router from 'next/router';
import { API_CLIENT_ID, API_HOST, API_PROTOCOL } from './constants';
import errorFactory from './errorFactory';
import vault from './vault';
var getMechanism = function getMechanism(organization) {
  return vault.getOrganization('mechanism', organization);
};
var isInternalMechanism = function isInternalMechanism(organization) {
  return getMechanism(organization) === 'internal';
};
export var isMobileAppMechanism = function isMobileAppMechanism(organization) {
  return getMechanism(organization) === 'mobile_app';
};
export var isThirdPartyMechanism = function isThirdPartyMechanism(organization) {
  return getMechanism(organization) === 'third_party';
};
export var isThirdPartyOAuthMechanism = function isThirdPartyOAuthMechanism(organization) {
  return getMechanism(organization) === 'third_party_oauth';
};
export var isTokenExpired = function isTokenExpired(organization) {
  var expiresOn = vault.getUser('expiresOn', organization);
  if (!expiresOn) {
    console.warn(errorFactory('utils.isTokenExpired', '`expiresOn` is null'));
    return true;
  }
  return expiresOn - Date.now() <= 60 * 1000;
};
export var getAccessToken = function getAccessToken(organization) {
  return vault.getUser('accessToken', organization);
};
export var isAuthenticated = function isAuthenticated(organization) {
  try {
    return Boolean(getAccessToken(organization));
  } catch (_unused) {
    return false;
  }
};
export var getBaseUrl = function getBaseUrl(organization) {
  var baseUrl = vault.getOrganization('baseUrl', organization);
  if (baseUrl) {
    return baseUrl;
  }
  if (!API_HOST) {
    throw errorFactory('utils.getBaseUrl', '`API_HOST` is undefined');
  }
  if (!API_PROTOCOL) {
    throw errorFactory('utils.getBaseUrl', '`API_PROTOCOL` is undefined');
  }
  if (!organization) {
    throw errorFactory('utils.getBaseUrl', 'organization is null');
  }
  return "".concat(API_PROTOCOL, "://").concat(organization, ".").concat(API_HOST);
};
export var getClientId = function getClientId(organization) {
  var apiClientId = API_CLIENT_ID;
  var oauthClientId = vault.getOrganization('oauthClientId', organization);
  if (isThirdPartyMechanism(organization) || isThirdPartyOAuthMechanism(organization)) {
    if (!oauthClientId) {
      throw errorFactory('utils.getClientId', '`oauthClientId` is null');
    }
    return oauthClientId;
  }
  if (!apiClientId) {
    throw errorFactory('utils.getClientId', '`API_CLIENT_ID` is undefined');
  }
  return apiClientId;
};
export var getConfigs = function getConfigs() {
  return vault.get('configs');
};
export var getIdentifierType = function getIdentifierType(organization) {
  return vault.getOrganization('identifierType', organization);
};
var getUsers = function getUsers() {
  return vault.get('users');
};
export var getListOfOrganizations = function getListOfOrganizations() {
  return Object.keys(getConfigs());
};
export var getListOfUsers = function getListOfUsers() {
  return Object.keys(getUsers());
};
export var getLoginUrl = function getLoginUrl(organization) {
  var baseUrl = getBaseUrl(organization);
  if (isInternalMechanism(organization)) {
    return "".concat(baseUrl, "/oauth/token");
  }
  var oauthTokenEndpoint = vault.getOrganization('oauthTokenEndpoint', organization);
  if (!oauthTokenEndpoint) {
    throw errorFactory('utils.getLoginUrl', "`oauthTokenEndpoint` is null for ".concat(getMechanism(organization)));
  }
  return oauthTokenEndpoint;
};
export var getLogoutUrl = function getLogoutUrl(organization) {
  var baseUrl = getBaseUrl(organization);
  return "".concat(baseUrl, "/oauth/revoke");
};
export var getOrganization = function getOrganization() {
  var router = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : Router;
  if (false) return undefined;
  var org = router.query.organization;
  if (Array.isArray(org)) throw Error('organization cannot be an array');
  if (org === '[organization]') return undefined;
  return org;
};
export var getLastActiveOrganization = function getLastActiveOrganization() {
  return vault.get('organization');
};
export var getRefreshTokenUrl = function getRefreshTokenUrl(organization) {
  var baseUrl = getBaseUrl(organization);
  var path = isThirdPartyMechanism(organization) ? '/api/third_party_authentication_proxy/token' : '/oauth/token';
  return "".concat(baseUrl).concat(path);
};
export var getResource = function getResource(organization) {
  var oauthTokenResource = vault.getOrganization('oauthTokenResource', organization);
  return isThirdPartyMechanism(organization) ? oauthTokenResource : null;
};
export var getSignoutUrl = function getSignoutUrl(organization) {
  if (isMobileAppMechanism(organization)) {
    return "".concat(eventApi.value.get('URN-Scheme'), "back");
  }
  if (isThirdPartyMechanism(organization)) {
    var signoutUrl = vault.getOrganization('externalSignoutUrl', organization);
    if (!signoutUrl) {
      throw errorFactory('utils.getSignoutUrl', '`externalSignoutUrl` is null for third_party');
    }
    return signoutUrl;
  }
  if (isThirdPartyOAuthMechanism(organization)) {
    var _signoutUrl = vault.getOrganization('oauthSignoutUrl', organization);
    if (!_signoutUrl) {
      throw errorFactory('utils.getSignoutUrl', '`oauthSignoutUrl` is null for third_party_oauth');
    }
    return _signoutUrl;
  }
  return null;
};
export var getThirdPartyLoginUrl = function getThirdPartyLoginUrl(organization, stateParam) {
  var oauthLoginUrl = vault.getOrganization('oauthLoginUrl', organization);
  if (isInternalMechanism(organization)) {
    throw errorFactory('utils.getThirdPartyLoginUrl', 'third party login URL is not available for internal mechanism');
  }
  if (!oauthLoginUrl) {
    throw errorFactory('utils.getThirdPartyLoginUrl', "`oauthLoginUrl` is null for ".concat(getMechanism(organization), " mechanism"));
  }
  return stateParam ? "".concat(oauthLoginUrl, "&state=").concat(stateParam) : oauthLoginUrl;
};
export var getRedirectUri = function getRedirectUri(organization) {
  var _URL = new URL(getThirdPartyLoginUrl(organization, null)),
    searchParams = _URL.searchParams;
  return searchParams.get('redirect_uri');
};
export var removeOrganization = function removeOrganization() {
  return vault.remove('organization');
};
export var saveTokens = function saveTokens(data, organization) {
  var users = vault.get('users');
  if (!organization) {
    throw errorFactory('utils.saveTokens', 'organization is null');
  }
  vault.set('users', _objectSpread(_objectSpread({}, users), {}, _defineProperty({}, organization, _objectSpread({
    userId: vault.getUser('userId', organization)
  }, data))));
  return data;
};
export var serializeTokenResponseData = function serializeTokenResponseData(data) {
  var accessToken = data.access_token,
    createdAt = data.created_at,
    expiresIn = data.expires_in,
    refreshToken = data.refresh_token;
  var expiresOn = data.expires_on ? data.expires_on * 1000 : undefined;
  if (!accessToken) {
    throw errorFactory('utils.serializeTokenResponseData', '`access_token` is not in response');
  }
  if (!expiresOn) {
    if (!createdAt) {
      throw errorFactory('utils.serializeTokenResponseData', '`created_at` is not in response');
    }
    if (!expiresIn) {
      throw errorFactory('utils.serializeTokenResponseData', '`expires_in` is not in response');
    }
    expiresOn = new Date((createdAt + expiresIn) * 1000).getTime();
  }
  if (!refreshToken) {
    throw errorFactory('utils.serializeTokenResponseData', '`refresh_token` is not in response');
  }
  return {
    accessToken: accessToken,
    expiresOn: expiresOn,
    refreshToken: refreshToken
  };
};
export var switchOrganization = function switchOrganization(organization) {
  var configs = vault.get('configs');
  if (Object.keys(configs).includes(organization)) {
    vault.set('organization', organization);
    return;
  }
  console.warn(errorFactory('utils.switchOrganization', 'organization is not is configs').message);
};