import { isIOS } from './utils';
import WebkitCallback from './webkit-callback';
var getUri = function getUri(url) {
  var required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var uri = new URL(url);
  uri.searchParams.append('required', String(required));
  return uri.toString();
};
var ended = function ended(url) {
  var required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var uri = getUri(url, required);
  if (isIOS()) return;
  WebkitCallback.videoEnded(uri);
};
var pause = function pause(url) {
  var required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var uri = getUri(url, required);
  if (isIOS()) return;
  WebkitCallback.videoPause(uri);
};
var play = function play(url) {
  var required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var uri = getUri(url, required);
  if (isIOS()) return;
  WebkitCallback.videoPlay(uri);
};
var playFullScreen = function playFullScreen(url) {
  var required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var uri = getUri(url, required);
  if (isIOS()) return;
  WebkitCallback.videoPlayFullScreen(uri);
};
export default {
  ended: ended,
  pause: pause,
  play: play,
  playFullScreen: playFullScreen
};