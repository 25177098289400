var trainingQueries = {
  actions: function actions(courseIds) {
    return ["training/actions/".concat(courseIds.join(','))];
  },
  profile: function profile() {
    return ['training/profile'];
  },
  favorites: function favorites() {
    return ['training/favorites'];
  },
  sectionCompletion: function sectionCompletion(id) {
    return ['training/entity/completions/section', id.toString()];
  },
  sectionCompletionList: function sectionCompletionList() {
    return ['training/entity/completions/section/list'];
  },
  learningPathListItem: function learningPathListItem(slug) {
    return ['training/learning-path/item', slug];
  },
  learningPathSectionItem: function learningPathSectionItem() {
    var slug = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
    return ['training/learning-path/section', slug];
  },
  coursePackageDetails: function coursePackageDetails(slug) {
    return ['training/course-package/details', slug];
  },
  contentBlockHighscores: function contentBlockHighscores() {
    return ['training/content-block-highscores'];
  },
  organizations: function organizations(identifier) {
    return ['training/organizations', identifier];
  },
  course: function course(slug) {
    return ['training/course', slug];
  },
  previewCourse: function previewCourse(slug) {
    return ['training/previewCourse', slug];
  },
  sections: function sections() {
    return ["training/sections"];
  },
  tags: function tags(tagId) {
    return ['training/tags', String(tagId)];
  },
  discoverCategoryList: function discoverCategoryList() {
    return ['training/discover/categoryList'];
  },
  discoverCategoryLearnings: function discoverCategoryLearnings(id) {
    return ['training/discover/categoryLearnings', String(id)];
  },
  notifications: function notifications() {
    return ['notifications'];
  },
  notificationsCount: function notificationsCount() {
    return ['notifications', 'count'];
  },
  notificationsList: function notificationsList() {
    return ['notifications', 'list'];
  }
};
export default trainingQueries;