import trigger from './trigger';
import { isAPIGte20 } from './utils';
import WebkitCallback from './webkit-callback';
var change = function change(fromId, toId) {
  if (!isAPIGte20()) return;
  trigger('chapter', 'change', [fromId, toId]);
};
var complete = function complete(id) {
  WebkitCallback.chapterCompleted(id);
};
export default {
  change: change,
  complete: complete
};