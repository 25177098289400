import { useEffect } from 'react';
import auth from '@motimate/auth';
import Logger from '@ui/utils/logger';
import { useRouter } from 'next/router';
var PageNotFound = function PageNotFound() {
  var router = useRouter();
  var organization = auth.getOrganization();
  var path = router.asPath;
  var redirect = organization && (path.startsWith('/admin') || path.startsWith('/contacts') || path.startsWith('/discover') || path.startsWith('/documents') || path.startsWith('/insights') || path.startsWith('/password') || path.startsWith('/profile') || path.startsWith('/pulse') || path.startsWith('/studio') || path.startsWith('/tasks') || path.startsWith('/today') || path.startsWith('/training'));
  useEffect(function () {
    if (redirect) {
      Logger.warning("Deprecated path \"".concat(path, "\""));
      router.push("/".concat(organization).concat(path));
      return;
    }
    throw new Error("Path ".concat(path, " not found"));
  }, []);
  return null;
};
export default PageNotFound;