import tinycolor from 'tinycolor2';
var APP_COLOR = "#26da9f";
export var generatePastelHSLFromString = function generatePastelHSLFromString(word) {
  if (!word || word && word.length < 2) {
    return 'hsla(0, 25%, 60%, 1)';
  }
  // eslint-disable-next-line no-bitwise
  var seed = word.charCodeAt(0) ^ word.charCodeAt(1);
  // eslint-disable-next-line no-plusplus
  var rand = Math.abs(Math.sin(seed++) * 10000) % 360;
  // eslint-disable-next-line no-bitwise
  return "hsla(".concat(~~rand, ", 25%, 60%, 1)");
};
export var hexToTwVar = function hexToTwVar(value) {
  var color = sanitizeColor(value);
  var _tinycolor$toRgb = tinycolor(color).toRgb(),
    r = _tinycolor$toRgb.r,
    g = _tinycolor$toRgb.g,
    b = _tinycolor$toRgb.b;
  return [r, g, b].join(' ');
};
export var sanitizeColor = function sanitizeColor(value) {
  var fallback = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : APP_COLOR;
  if (value === 'transparent') return tinycolor(value).toHex8String();
  if (!value) return fallback;
  var color = tinycolor(value);
  if (color.isValid()) return color.toHexString();
  console.warn("\"".concat(value, "\" is not valid HEX color"));
  return sanitizeColor(fallback);
};
export var rgba = function rgba(value, alpha) {
  var color = tinycolor(value);
  return color.setAlpha(alpha).toRgbString();
};