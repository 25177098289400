import { nanoid } from 'nanoid';
var EXPIRES_IN = 30 * 24 * 60 * 60 * 1000;
export var clearExpiredStates = function clearExpiredStates() {
  Object.keys(localStorage).forEach(function (key) {
    var state = retrieveState(key, {
      removeOnError: false,
      removeOnRetrieve: false
    });
    if (state && state.expiresOn < new Date().getTime()) {
      localStorage.removeItem(key);
    }
  });
};
export var storeState = function storeState(redirectUrl) {
  var stateParam = nanoid();
  var state = JSON.stringify({
    expiresOn: new Date().getTime() + EXPIRES_IN,
    redirectUrl: redirectUrl
  });
  localStorage.setItem(stateParam, state);
  return stateParam;
};
export var retrieveState = function retrieveState(stateParam, options) {
  var _options$removeOnErro, _options$removeOnRetr;
  var removeOnError = (_options$removeOnErro = options === null || options === void 0 ? void 0 : options.removeOnError) !== null && _options$removeOnErro !== void 0 ? _options$removeOnErro : true;
  var removeOnRetrieve = (_options$removeOnRetr = options === null || options === void 0 ? void 0 : options.removeOnRetrieve) !== null && _options$removeOnRetr !== void 0 ? _options$removeOnRetr : true;
  var state = localStorage.getItem(stateParam);
  if (!state) return null;
  try {
    var json = JSON.parse(state);
    if (typeof json.expiresOn !== 'number' || typeof json.redirectUrl !== 'string') {
      throw new Error('state is invalid');
    }
    if (json.expiresOn < new Date().getTime()) {
      throw new Error('state has expired');
    }
    if (removeOnRetrieve) {
      localStorage.removeItem(stateParam);
    }
    return json;
  } catch (e) {
    if (removeOnError) {
      localStorage.removeItem(stateParam);
    }
    return null;
  }
};