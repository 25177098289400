import { loginInternal, loginMobileApp, loginThirdParty } from './src/login';
import { logoutOrganization, logoutUser } from './src/logout';
import { resetPassword, resetPasswordRequest } from './src/password';
import refreshTokens from './src/refreshTokens';
import { setupOrganization, setupUser } from './src/setup';
import { signout } from './src/signout';
import { clearExpiredStates, retrieveState, storeState } from './src/state';
import { getAccessToken, getBaseUrl, getConfigs, getIdentifierType, getLastActiveOrganization, getListOfOrganizations, getListOfUsers, getOrganization, getRedirectUri, getRefreshTokenUrl, getSignoutUrl, getThirdPartyLoginUrl, isAuthenticated, isThirdPartyMechanism, isThirdPartyOAuthMechanism, isTokenExpired, removeOrganization, switchOrganization } from './src/utils';
var auth = {
  isAuthenticated: isAuthenticated,
  isThirdPartyMechanism: isThirdPartyMechanism,
  isThirdPartyOAuthMechanism: isThirdPartyOAuthMechanism,
  isTokenExpired: isTokenExpired,
  clearExpiredStates: clearExpiredStates,
  getAccessToken: getAccessToken,
  getBaseUrl: getBaseUrl,
  getConfigs: getConfigs,
  getIdentifierType: getIdentifierType,
  getListOfOrganizations: getListOfOrganizations,
  getListOfUsers: getListOfUsers,
  getRedirectUri: getRedirectUri,
  getRefreshTokenUrl: getRefreshTokenUrl,
  getOrganization: getOrganization,
  getLastActiveOrganization: getLastActiveOrganization,
  getSignoutUrl: getSignoutUrl,
  getThirdPartyLoginUrl: getThirdPartyLoginUrl,
  login: loginInternal,
  loginMobileApp: loginMobileApp,
  loginThirdParty: loginThirdParty,
  logoutOrganization: logoutOrganization,
  logout: logoutUser,
  removeOrganization: removeOrganization,
  refreshTokens: refreshTokens,
  resetPassword: resetPassword,
  resetPasswordRequest: resetPasswordRequest,
  retrieveState: retrieveState,
  setup: setupOrganization,
  setupUser: setupUser,
  signout: signout,
  storeState: storeState,
  switchOrganization: switchOrganization
};
export default auth;