import { p } from '@motimate/utils/routing';
import Router from 'next/router';
var resourcesPaths = {
  courses: 'courses',
  course_packages: 'course-packages',
  learning_paths: 'learning-paths'
};
export { p };
export var detectStudioResourceType = function detectStudioResourceType() {
  var route = Router.route;
  if (route.includes('/[organization]/studio/courses')) return 'courses';
  if (route.includes('/[organization]/studio/course-packages')) return 'course_packages';
  if (route.includes('/[organization]/studio/learning-paths')) return 'learning_paths';
  return null;
};
export var isStudio = function isStudio(url) {
  return Boolean(url === null || url === void 0 ? void 0 : url.startsWith(p('/studio')));
};
export var pathCourseEdit = function pathCourseEdit(course) {
  var language = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  var languagePath = language ? "?language=".concat(language) : '';
  return p("/studio/courses/".concat(course.id, "/edit").concat(languagePath));
};
export var pathBackToFolder = function pathBackToFolder(resourceType, resource) {
  var _resource$folder;
  var folderId = resource === null || resource === void 0 || (_resource$folder = resource.folder) === null || _resource$folder === void 0 ? void 0 : _resource$folder.id;
  var folderQuery = folderId ? "?folderId=".concat(folderId) : '';
  return p("/studio/".concat(resourcesPaths[resourceType]).concat(folderQuery));
};
export var pathCourseEditChapter = function pathCourseEditChapter(course, chapter, language) {
  var languagePath = language ? "?language=".concat(language) : '';
  return p("/studio/courses/".concat(course.id, "/edit/chapter/").concat(chapter.slug).concat(languagePath));
};
export var pathCourseEditChapterEdit = function pathCourseEditChapterEdit(course, chapter, language) {
  var languagePath = language ? "?language=".concat(language) : '';
  return p("/studio/courses/".concat(course.id, "/edit/chapter/").concat(chapter.slug, "/edit").concat(languagePath));
};
export var pathCourseSettings = function pathCourseSettings(course, language) {
  var languagePath = language && language !== course.sourceLanguage ? "/languages/".concat(language) : '';
  return p("/studio/courses/".concat(course.id).concat(languagePath));
};
export var pathCoursePackageEdit = function pathCoursePackageEdit(coursePackage) {
  return p("/studio/course-packages/".concat(coursePackage.id, "/edit"));
};
export var pathPreviewCourse = function pathPreviewCourse(course, language) {
  return p("/training/preview/course/".concat(course.slug).concat(language ? "?language=".concat(language) : ''));
};
export var pathPreviewCourseChapter = function pathPreviewCourseChapter(course, chapter, language, mobilePreview) {
  return p("/training/preview/course/".concat(course.slug, "/chapter/").concat(chapter.slug).concat(mobilePreview ? '/mobile-preview' : '').concat(language ? "?language=".concat(language) : ''));
};
export var pathTrainingCourse = function pathTrainingCourse(course) {
  return p("/training/course/".concat(course.slug));
};
export var pathTrainingCourseChapter = function pathTrainingCourseChapter(course, chapter) {
  return p("/training/course/".concat(course.slug, "/chapter/").concat(chapter.slug));
};