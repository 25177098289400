import { KEY_PREFIX } from './constants';
import errorFactory from './errorFactory';
var serverStorage = {
  getItem: function getItem() {
    return null;
  },
  removeItem: function removeItem() {},
  setItem: function setItem() {},
  clear: function clear() {},
  key: function key() {
    return null;
  },
  length: 0
};
var initialVault = {
  organization: null,
  configs: {},
  users: {}
};
var storage = true || false ? localStorage : serverStorage;
var open = function open() {
  var _storage$getItem;
  return JSON.parse((_storage$getItem = storage.getItem(KEY_PREFIX)) !== null && _storage$getItem !== void 0 ? _storage$getItem : JSON.stringify(initialVault));
};
var destroy = function destroy() {
  storage.removeItem(KEY_PREFIX);
};
var cleanOrganization = function cleanOrganization(organization) {
  var vault = open();
  if (!organization) {
    console.warn(errorFactory('vault.cleanOrganization', 'organization is null').message);
    return;
  }
  if (organization in vault.configs) {
    delete vault.configs[organization];
  }
  storage.setItem(KEY_PREFIX, JSON.stringify(vault));
};
var cleanUser = function cleanUser(organization) {
  var vault = open();
  if (!organization) {
    console.warn(errorFactory('vault.cleanUser', 'organization is null').message);
    return;
  }
  if (organization in vault.users) {
    delete vault.users[organization];
  }
  storage.setItem(KEY_PREFIX, JSON.stringify(vault));
};
var get = function get(key) {
  return open()[key];
};
var getOrganization = function getOrganization(key, organizationIdentifier) {
  var _organization$key;
  var vault = open();
  if (!organizationIdentifier) {
    console.warn(errorFactory('vault.getOrganization', 'organization is null').message);
    return null;
  }
  var organization = vault['configs'][organizationIdentifier];
  return (_organization$key = organization === null || organization === void 0 ? void 0 : organization[key]) !== null && _organization$key !== void 0 ? _organization$key : null;
};
var getUser = function getUser(key, organizationIdentifier) {
  var _user$key;
  if (false) return null;
  var vault = open();
  if (!organizationIdentifier) {
    console.warn(errorFactory('vault.getUser', "organization is null for key \"".concat(key, "\"")).message);
    return null;
  }
  var user = vault['users'][organizationIdentifier];
  return (_user$key = user === null || user === void 0 ? void 0 : user[key]) !== null && _user$key !== void 0 ? _user$key : null;
};
var remove = function remove(key) {
  var vault = open();
  delete vault[key];
  storage.setItem(KEY_PREFIX, JSON.stringify(vault));
};
var removeUser = function removeUser(key, organizationIdentifier) {
  var vault = open();
  if (!organizationIdentifier) {
    console.warn(errorFactory('vault.removeUser', "organization is null for key \"".concat(key, "\"")).message);
    return null;
  }
  var user = vault.users[organizationIdentifier];
  if (!user) {
    console.warn(errorFactory('vault.removeUser', "user for `".concat(organizationIdentifier, "` is undefined")).message);
    return;
  }
  delete user[key];
  vault.users[organizationIdentifier] = user;
  storage.setItem(KEY_PREFIX, JSON.stringify(vault));
};
var set = function set(key, value) {
  var vault = open();
  vault[key] = value;
  storage.setItem(KEY_PREFIX, JSON.stringify(vault));
};
var setOrganization = function setOrganization(key, value, organizationIdentifier) {
  var vault = open();
  if (!organizationIdentifier) {
    console.warn(errorFactory('vault.setOrganization', 'organization is null').message);
    return;
  }
  var organization = vault.configs[organizationIdentifier];
  if (!organization) {
    console.warn(errorFactory('vault.setOrganization', "organization `".concat(organizationIdentifier, "` is undefined")).message);
    return;
  }
  organization[key] = value;
  vault.configs[organizationIdentifier] = organization;
  storage.setItem(KEY_PREFIX, JSON.stringify(vault));
};
var setUser = function setUser(key, value, organizationIdentifier) {
  var vault = open();
  if (!organizationIdentifier) {
    console.warn(errorFactory('vault.getUser', 'organization is null').message);
    return;
  }
  var user = vault.users[organizationIdentifier];
  if (!user) {
    console.warn(errorFactory('vault.getUser', "user for `".concat(organizationIdentifier, "` is undefined")).message);
    return;
  }
  user[key] = value;
  vault.users[organizationIdentifier] = user;
  storage.setItem(KEY_PREFIX, JSON.stringify(vault));
};
var vault = {
  open: open,
  cleanOrganization: cleanOrganization,
  cleanUser: cleanUser,
  destroy: destroy,
  get: get,
  getOrganization: getOrganization,
  getUser: getUser,
  remove: remove,
  removeUser: removeUser,
  set: set,
  setOrganization: setOrganization,
  setUser: setUser
};
export default vault;