import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
// Language keys follow ISO 639-1
// https://www.w3schools.com/tags/ref_language_codes.asp
var LANGUAGES = {
  cs: {
    enabled: true,
    english: 'Czech',
    "native": 'Čeština'
  },
  da: {
    enabled: true,
    english: 'Danish',
    "native": 'Dansk'
  },
  de: {
    enabled: true,
    english: 'German',
    "native": 'Deutsch'
  },
  en: {
    enabled: true,
    english: 'English',
    "native": 'English'
  },
  es: {
    enabled: true,
    english: 'Spanish',
    "native": 'Español'
  },
  fi: {
    enabled: true,
    english: 'Finnish',
    "native": 'Suomi'
  },
  fr: {
    enabled: true,
    english: 'French',
    "native": 'Français'
  },
  hr: {
    enabled: true,
    english: 'Croatian',
    "native": 'Hrvatski'
  },
  hu: {
    enabled: true,
    english: 'Hungarian',
    "native": 'Magyar'
  },
  it: {
    enabled: true,
    english: 'Italian',
    "native": 'Italiano'
  },
  lt: {
    enabled: true,
    english: 'Lithuanian',
    "native": 'Lietuvis'
  },
  lv: {
    enabled: true,
    english: 'Latvian',
    "native": 'Latvietis'
  },
  nb: {
    enabled: true,
    english: 'Norwegian Bokmål',
    "native": 'Norsk, bokmål'
  },
  nl: {
    enabled: true,
    english: 'Dutch',
    "native": 'Nederlands'
  },
  nn: {
    enabled: true,
    english: 'Norwegian Nynorsk',
    "native": 'Norsk nynorsk'
  },
  pl: {
    enabled: true,
    english: 'Polish',
    "native": 'Polski'
  },
  pt: {
    enabled: true,
    english: 'Portuguese',
    "native": 'Português'
  },
  ro: {
    enabled: true,
    english: 'Romanian',
    "native": 'Română'
  },
  ru: {
    enabled: true,
    english: 'Russian',
    "native": 'Русский'
  },
  se: {
    enabled: true,
    english: 'Northern Sámi',
    "native": 'Davvisámegiella'
  },
  sq: {
    enabled: true,
    english: 'Albanian',
    "native": 'Shqip'
  },
  sr: {
    enabled: true,
    english: 'Serbian',
    "native": 'Srpski'
  },
  sv: {
    enabled: true,
    english: 'Swedish',
    "native": 'Svenska'
  },
  tr: {
    enabled: true,
    english: 'Turkish',
    "native": 'Türkçe'
  }
};
export var ENABLED_LANGUAGES = Object.keys(LANGUAGES).reduce(function (languages, key) {
  var language = LANGUAGES[key];
  if (!language.enabled) return languages;
  return _objectSpread(_objectSpread({}, languages), {}, _defineProperty({}, key, language));
}, {});
export default ENABLED_LANGUAGES;