import ErrorPage from '@/components/error/page';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var ErrorBoundary = function ErrorBoundary(_ref) {
  var error = _ref.error,
    componentStack = _ref.componentStack,
    eventId = _ref.eventId,
    resetError = _ref.resetError;
  return _jsx(ErrorPage, {
    error: error,
    componentStack: componentStack,
    eventId: eventId,
    resetError: resetError
  });
};
export default ErrorBoundary;