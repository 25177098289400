import trigger from './trigger';
import { isAPIGte20 } from './utils';
import WebkitCallback from './webkit-callback';
var getUri = function getUri(url) {
  var required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var startTime = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var uri = new URL(url);
  uri.searchParams.append('required', String(required));
  uri.searchParams.append('startTime', String(startTime));
  return uri.toString();
};
var pause = function pause(id) {
  if (!isAPIGte20()) return;
  trigger('audio', 'pause', [id]);
};
var play = function play(id, url, title) {
  var required = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  var currentTime = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 0;
  var uri = getUri(url, required, currentTime);
  if (!isAPIGte20()) {
    WebkitCallback.playAudio(title, uri);
    return;
  }
  trigger('audio', 'play', [id, url, title, required, currentTime]);
};
export default {
  pause: pause,
  play: play
};