import { mapKeysDeepToCamelCase } from '@motimate/utils';
import Value from './value';
export var parseParam = function parseParam(param) {
  var numberParam = Number(param);
  if (!Number.isNaN(numberParam)) return numberParam;
  var decoded = decodeURIComponent(param);
  try {
    return mapKeysDeepToCamelCase(JSON.parse(decoded));
  } catch (_unused) {}
  return decoded;
};
var getEventApiVersion = function getEventApiVersion() {
  var _Value$get;
  var defaultEventApiVersion = 1;
  var eventApiVersion = Number.parseFloat((_Value$get = Value.get('Event-Api-Version')) !== null && _Value$get !== void 0 ? _Value$get : String(defaultEventApiVersion));
  if (Number.isNaN(eventApiVersion)) {
    return defaultEventApiVersion;
  }
  return eventApiVersion;
};
export var isAPIGte20 = function isAPIGte20() {
  return getEventApiVersion() >= 2;
};
export var isAPIGte21 = function isAPIGte21() {
  return getEventApiVersion() >= 2.1;
};
export var isIOS = function isIOS() {
  var _Value$get2;
  return ((_Value$get2 = Value.get('Embedded')) !== null && _Value$get2 !== void 0 ? _Value$get2 : '').toLowerCase() === 'ios';
};