import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      useErrorBoundary: true
    },
    mutations: {}
  }
});
var ReactQueryProvider = function ReactQueryProvider(_ref) {
  var children = _ref.children;
  return _jsx(QueryClientProvider, {
    client: queryClient,
    children: children
  });
};
export default ReactQueryProvider;