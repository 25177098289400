import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["data"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useQuery } from '@tanstack/react-query';
import featureFlagsResources from '@/lib/resources/oneapp/flags';
export function useOneAppFeatureFlags(config) {
  var _useQuery = useQuery(['feature-flags'], featureFlagsResources.get, _objectSpread({
      enabled: false
    }, config)),
    data = _useQuery.data,
    hookResults = _objectWithoutProperties(_useQuery, _excluded);
  return _objectSpread(_objectSpread({}, hookResults), {}, {
    oneAppFlags: data
  });
}