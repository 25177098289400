import React from 'react';
import { useRouter } from 'next/router';
import { useOneAppFeatureFlags } from '@/lib/hooks/useOneAppFeatureFlags';
import NotFoundPage from '@/components/page-not-found';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
var OrganizationFeatureFlagsCheck = function OrganizationFeatureFlagsCheck(_ref) {
  var children = _ref.children;
  var _useRouter = useRouter(),
    route = _useRouter.route;
  var _useOneAppFeatureFlag = useOneAppFeatureFlags(),
    oneAppFlags = _useOneAppFeatureFlag.oneAppFlags,
    isSuccess = _useOneAppFeatureFlag.isSuccess;
  if (route.startsWith('/tasks') && !(oneAppFlags !== null && oneAppFlags !== void 0 && oneAppFlags.tasks)) {
    if (!isSuccess) return null;
    return _jsx(NotFoundPage, {});
  }
  if (route.startsWith('/documents') && !(oneAppFlags !== null && oneAppFlags !== void 0 && oneAppFlags.documents)) {
    if (!isSuccess) return null;
    return _jsx(NotFoundPage, {});
  }
  if (route.startsWith('/documents/category') && route.includes('/course/') && !(oneAppFlags !== null && oneAppFlags !== void 0 && oneAppFlags.training)) {
    if (!isSuccess) return null;
    return _jsx(NotFoundPage, {});
  }
  if (route.startsWith('/contacts') && !(oneAppFlags !== null && oneAppFlags !== void 0 && oneAppFlags.contacts)) {
    if (!isSuccess) return null;
    return _jsx(NotFoundPage, {});
  }
  if (route.startsWith('/pulse') && !(oneAppFlags !== null && oneAppFlags !== void 0 && oneAppFlags.wall)) {
    if (!isSuccess) return null;
    return _jsx(NotFoundPage, {});
  }
  return _jsx(_Fragment, {
    children: children
  });
};
export default OrganizationFeatureFlagsCheck;