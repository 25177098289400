import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
export var snakeCase = function snakeCase(text) {
  return Object.keys(snakeCaseKeys(_defineProperty({}, text, '')))[0];
};
export var camelCase = function camelCase(text) {
  return Object.keys(camelCaseKeys(_defineProperty({}, text, '')))[0];
};
export var truncate = function truncate(string, options) {
  if (!string) return string;
  var omission = '…';
  var length = (options === null || options === void 0 ? void 0 : options.length) || 30;
  if (string.length <= length) return string;
  var truncated = string.slice(0, length - omission.length);
  if (options !== null && options !== void 0 && options.separator) {
    var separatorIndex = -1;
    if (typeof options.separator === 'string') {
      separatorIndex = truncated.lastIndexOf(options.separator);
    } else {
      var matches = truncated.match(options.separator);
      if (matches && matches.length) {
        var lastMatch = matches[matches.length - 1];
        separatorIndex = truncated.lastIndexOf(lastMatch);
      }
    }
    if (separatorIndex !== -1) {
      truncated = truncated.slice(0, separatorIndex);
    }
  }
  return truncated + omission;
};