import Router from 'next/router';
export var p = function p(path) {
  var router = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : Router;
  var organization = router.query.organization;
  if (!organization) {
    throw new Error('path-utils.p(): organization is null');
  }
  if (Array.isArray(organization)) {
    throw new Error('path-utils.p(): organization is array');
  }
  return "/".concat(organization.toLowerCase()).concat(path === '/' ? '' : path);
};