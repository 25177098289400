import http from './http';
import { getAccessToken, getClientId, getLogoutUrl, removeOrganization } from './utils';
import vault from './vault';
export var logoutUser = function logoutUser(organization) {
  var clientId = getClientId(organization);
  var token = getAccessToken(organization);
  var logoutUrl = getLogoutUrl(organization);
  var logoutPromise = token ? http(logoutUrl, {
    client_id: clientId,
    token: token
  }, {
    headers: {
      Authorization: "Bearer ".concat(token)
    }
  }) : Promise.resolve();
  return logoutPromise["catch"](function () {})["finally"](function () {
    vault.cleanUser(organization);
  });
};
export var logoutOrganization = function logoutOrganization(organization) {
  return logoutUser(organization).then(function () {
    vault.cleanOrganization(organization);
    removeOrganization();
  });
};