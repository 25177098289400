import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["enabled", "onSuccess", "useErrorBoundary"];
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import auth from '@motimate/auth';
import { useQuery } from '@tanstack/react-query';
import { sanitizeColor } from '@ui/utils/color';
import { useRouter } from 'next/router';
import organizationResources from '@/lib/resources/organizations';
import UserAgent from '@/lib/user-agent';
import { getInitialLocale, useIntlContext } from '../locale';
export var useOrganizationQuery = function useOrganizationQuery() {
  var _queryOptions$staleTi;
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      redux: true
    },
    redux = _ref.redux;
  var queryOptions = arguments.length > 1 ? arguments[1] : undefined;
  var org = arguments.length > 2 ? arguments[2] : undefined;
  var router = useRouter();
  var organization = router.query.organization || org;
  var queryFn = UserAgent.isMobileApp() ? function () {
    return Promise.resolve(UserAgent.getOrganizationData());
  } : function () {
    return organizationResources.get(String(organization));
  };
  var _ref2 = queryOptions || {},
    _ = _ref2.enabled,
    _onSuccess = _ref2.onSuccess,
    _useErrorBoundary = _ref2.useErrorBoundary,
    rest = _objectWithoutProperties(_ref2, _excluded);
  var enabled = typeof (queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.enabled) === 'boolean' ? queryOptions.enabled : true;
  var _useIntlContext = useIntlContext(),
    onLocaleChange = _useIntlContext.onLocaleChange;
  return useQuery(['organization', organization], queryFn, _objectSpread({
    enabled: router.isReady && Boolean(organization) && enabled,
    useErrorBoundary: false,
    retry: 0,
    staleTime: (_queryOptions$staleTi = queryOptions === null || queryOptions === void 0 ? void 0 : queryOptions.staleTime) !== null && _queryOptions$staleTi !== void 0 ? _queryOptions$staleTi : 5 * 60 * 1000,
    onSuccess: function onSuccess(organization) {
      var _authentication$oauth, _authentication$oauth2, _queryOptions$onSucce;
      if (!redux) return;
      if (!organization) return;
      var authentication = organization.authentication;
      var authOauthClientId = (_authentication$oauth = (_authentication$oauth2 = authentication.oauth_client_ids) === null || _authentication$oauth2 === void 0 ? void 0 : _authentication$oauth2.web) !== null && _authentication$oauth !== void 0 ? _authentication$oauth : null;
      auth.setup(organization.identifier_name, UserAgent.isMobileApp() ? {
        baseUrl: organization ? organization.base_url : '',
        mechanism: 'mobile_app',
        name: organization ? organization.name : '',
        logo: {
          url: organization === null || organization === void 0 ? void 0 : organization.assets.logo
        },
        oauthLoginUrl: authentication.mobile_training_third_party_oauth_login_url
      } : {
        baseUrl: organization.base_url,
        externalSignoutUrl: authentication.external_signout_redirect_url,
        externalVendor: authentication.external_vendor,
        identifierType: authentication.identifier_type,
        logo: {
          url: organization.assets.logo
        },
        mechanism: authentication.mechanism,
        name: organization.name,
        oauthClientId: authOauthClientId,
        oauthHost: authentication.external_oauth_host,
        oauthLoginUrl: authentication.web_training_third_party_oauth_login_url,
        oauthResource: authentication.external_oauth_resource,
        oauthSignoutUrl: authentication.web_training_third_party_oauth_signout_url,
        oauthTokenEndpoint: authentication.external_oauth_token_endpoint,
        oauthTokenResource: authentication.external_oauth_token_resource
      });
      onLocaleChange({
        locale: getInitialLocale({
          preferredFallbackLanguage: organization.preferred_language
        })
      });
      queryOptions === null || queryOptions === void 0 || (_queryOptions$onSucce = queryOptions.onSuccess) === null || _queryOptions$onSucce === void 0 || _queryOptions$onSucce.call(queryOptions, organization);
    }
  }, rest));
};
export var useOrganizationColor = function useOrganizationColor() {
  var color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'main';
  var _useOrganizationQuery = useOrganizationQuery({
      redux: false
    }),
    organization = _useOrganizationQuery.data;
  return sanitizeColor(organization === null || organization === void 0 ? void 0 : organization.colors[color]);
};