import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getOrganization, switchOrganization } from './utils';
export var useSyncLocalStorageOrgWithUrl = function useSyncLocalStorageOrgWithUrl() {
  var _useRouter = useRouter(),
    query = _useRouter.query,
    asPath = _useRouter.asPath,
    replace = _useRouter.replace,
    basePath = _useRouter.basePath;
  var urlOrg = query.organization;
  useEffect(function () {
    var syncOrgs = function syncOrgs() {
      var authOrg = getOrganization();
      if (urlOrg && authOrg !== urlOrg && typeof urlOrg === 'string') {
        switchOrganization(urlOrg);
      }
    };
    syncOrgs();
    addEventListener('focus', syncOrgs);
    return function () {
      window.removeEventListener('focus', syncOrgs);
    };
  }, [urlOrg, replace, asPath, basePath]);
};