import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
var _process$env$AMPLITUD;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { useEffect } from 'react';
import auth from '@motimate/auth';
import { objectKeys } from '@motimate/utils';
import { useQueryClient } from '@tanstack/react-query';
import Cookies from 'universal-cookie';
import { ampli } from '@/ampli';
import trainingQueries from '@/training/queries';
import { isTabletScreen } from '@/components/utils/style';
import { hasPerformanceConsent } from './onetrust-utils';
import UserAgent from './user-agent';
var AMPLITUDE_API_KEY = (_process$env$AMPLITUD = process.env.AMPLITUDE_API_KEY) !== null && _process$env$AMPLITUD !== void 0 ? _process$env$AMPLITUD : '';
export var mapFeaturedPostsFilters = function mapFeaturedPostsFilters(filters) {
  var filtersSelected = [];
  if (filters !== null && filters !== void 0 && filters.groupId) filtersSelected.push('group');
  if (filters !== null && filters !== void 0 && filters.startTime) filtersSelected.push('visible_from_date');
  if (filters !== null && filters !== void 0 && filters.endTime) filtersSelected.push('visible_to_date');
  if (filters !== null && filters !== void 0 && filters.status) filtersSelected.push('publish_status');
  if (filtersSelected.length === 0) filtersSelected.push('none');
  return filtersSelected;
};
export var mapNotificationType = function mapNotificationType(value, urn) {
  if (value === 'CUSTOM') {
    return urn.includes('course_package') ? 'package' : 'other';
  }
  switch (value) {
    case 'NEW_COURSE':
      return 'moti';
    case 'NEW_LEARNING_PATH':
      return 'learning_path';
    case 'NEW_LIKE_ON_POST':
    case 'NEW_LIKE_ON_OWN_POST':
    case 'MULTIPLE_NEW_LIKES_ON_OWN_POST':
    case 'MULTIPLE_NEW_LIKES_ON_OWN_COMMENT':
      return 'pulse_like';
    case 'NEW_POST':
      return 'pulse_post';
    case 'NEW_FEATURED_POST':
      return 'pulse_featured_post';
    case 'NEW_COMMENT_ON_OWN_POST':
    case 'NEW_COMMENT_ON_FOLLOWED_POST':
      return 'pulse_comment';
    case 'MENTIONED_IN_POST':
    case 'MENTIONED_IN_COMMENT':
      return 'pulse_mention';
    case 'NEW_TASK':
      return 'task';
    default:
      return 'other';
  }
};
export function setDiplomaContentType(contentType) {
  var mappedLearnings = {
    course: 'moti',
    course_package: 'package',
    learning_path: 'learning_path'
  };
  return mappedLearnings[contentType];
}
export function countLearningByType(learnings) {
  var total = {
    motiCount: 0,
    packageCount: 0,
    pathCount: 0
  };
  if (!learnings) return total;
  learnings.forEach(function (learning) {
    if (learning.type === 'course') return total.motiCount++;
    if (learning.type === 'course_package') return total.packageCount++;
    if (learning.type === 'learning_path') return total.pathCount++;
  });
  return total;
}
export function setPlatformType() {
  if (UserAgent.isAndroid()) return 'android';
  if (UserAgent.isIOS()) return 'ios';
  if (isTabletScreen()) return 'web_mobile';
  return 'web_desktop';
}
export var getOauthLoginUrl = function getOauthLoginUrl(identifier) {
  if (!identifier) return null;
  try {
    return auth.getThirdPartyLoginUrl(identifier, null);
  } catch (_unused) {
    return null;
  }
};
export var useAmplitudeMyTrainingOpened = function useAmplitudeMyTrainingOpened() {
  var sectionList = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var _ref = arguments.length > 1 ? arguments[1] : undefined,
    isActive = _ref.isActive;
  var queryClient = useQueryClient();
  useEffect(function () {
    var _queryClient$getQuery;
    if (!isActive) return;
    var rankingData = (_queryClient$getQuery = queryClient.getQueryCache().find(trainingQueries.profile())) === null || _queryClient$getQuery === void 0 || (_queryClient$getQuery = _queryClient$getQuery.state) === null || _queryClient$getQuery === void 0 ? void 0 : _queryClient$getQuery.data;
    isAmpliLoaded(function () {
      var _rankingData$user$pro, _rankingData$user, _rankingData$leaderbo, _rankingData$leaderbo2;
      ampli.myTrainingOpened(_objectSpread(_objectSpread({
        assignmentCount: sectionList.reduce(function (sum, e) {
          return sum + Number(e.itemsCount);
        }, 0),
        assignmentCompletedCount: sectionList.reduce(function (sum, e) {
          if (e.type !== 'CONTINUE_SECTION') {
            return sum + Number(e.completedItemsCount);
          }
          return sum;
        }, 0),
        playlistCount: sectionList.length
      }, countLearningByType(sectionList.flatMap(function (list) {
        return list.items;
      }))), {}, {
        userTotalScore: (_rankingData$user$pro = rankingData === null || rankingData === void 0 || (_rankingData$user = rankingData.user) === null || _rankingData$user === void 0 ? void 0 : _rankingData$user.progress) !== null && _rankingData$user$pro !== void 0 ? _rankingData$user$pro : 0,
        userLeaderboardRank: (_rankingData$leaderbo = rankingData === null || rankingData === void 0 || (_rankingData$leaderbo2 = rankingData.leaderboard) === null || _rankingData$leaderbo2 === void 0 ? void 0 : _rankingData$leaderbo2.position) !== null && _rankingData$leaderbo !== void 0 ? _rankingData$leaderbo : 0
      }));
    });
  }, [isActive, queryClient, sectionList]);
};
export var useAmplitudeMyTrainingPlaylistOpened = function useAmplitudeMyTrainingPlaylistOpened(playlist) {
  useEffect(function () {
    if (!playlist) return;
    var playlistItemsCount = playlist.type === 'CONTINUE_SECTION' ? 0 : playlist.completedItemsCount;
    isAmpliLoaded(function () {
      var _playlist$id;
      ampli.playlistOpened(_objectSpread({
        assignmentCount: playlist.itemsCount,
        assignmentCompletedCount: playlistItemsCount,
        playlistNumber: (_playlist$id = playlist.id) !== null && _playlist$id !== void 0 ? _playlist$id : 0
      }, countLearningByType(playlist.items)));
    });
  }, [playlist]);
};
export function setThirdPartyLoginTypeProvider(url) {
  if (!url) return 'unknown';
  if (url.search(/microsoft/) !== -1) return 'microsoft_sso';
  if (url.search(/google/) !== -1) return 'google_sso';
  if (url.search(/auth0/) !== -1) return 'auth0_sso';
  return 'oauth_sso';
}
var getOptOut = function getOptOut() {
  return UserAgent.isMobileApp() ? false : !hasPerformanceConsent();
};
export var isAmpliLoaded = function isAmpliLoaded(func) {
  if (!ampli.isLoaded) return;
  func();
};
export var initAmplitude = function initAmplitude() {
  var cookies = new Cookies(null, {
    path: '/'
  });
  var optOut = getOptOut();
  if (optOut) {
    objectKeys(cookies.getAll()).forEach(function (name) {
      if (typeof name !== 'string') return;
      if (!name.startsWith('AMP_')) return;
      cookies.remove(name, {
        path: '/'
      });
    });
  }
  if (!AMPLITUDE_API_KEY || optOut) return;
  ampli.load({
    client: {
      apiKey: AMPLITUDE_API_KEY,
      configuration: {
        attribution: {
          disabled: true
        }
      }
    }
  });
};