import Color from 'color';
export var sampleColors = ['#3366CC', '#A05AF2', '#975AF3', '#CE77FF', '#FF4EC0', '#E00053', '#FF404F', '#FF812D', '#FDD700', '#C4E300', '#8DBC00', '#009999', '#0099CC', '#000000'];
export var darken = function darken(value) {
  var ratio = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.15;
  try {
    return Color(value).darken(ratio).hex();
  } catch (_unused) {
    return Color('#000000').alpha(ratio).string();
  }
};
export var isHex = function isHex(value) {
  return Boolean(value && /^#([0-9A-F]{6})$/i.test(value));
};
export var isRgb = function isRgb(value) {
  return Boolean(value && (/^rgb\(\d+, \d+, \d+\)$/.test(value) || /^rgba\(\d+, \d+, \d+, \d+\)$/.test(value)));
};
export var lighten = function lighten(value) {
  var ratio = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0.15;
  try {
    return Color(value).lighten(ratio).hex();
  } catch (_unused2) {
    return Color('#000000').lighten(ratio).string();
  }
};
export var rgba = function rgba(color) {
  var alpha = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  try {
    return Color(color).alpha(alpha).string();
  } catch (_unused3) {
    return Color('#000000').alpha(alpha).string();
  }
};