import audio from './src/audio';
import auth from './src/auth';
import chapter from './src/chapter';
import clipboard from './src/clipboard';
import document from './src/document';
import fullScreen from './src/fullScreen';
import { useEventApi } from './src/hooks';
import listenOn from './src/listenOn';
import once from './src/once';
import qrScanner from './src/qrScanner';
import render from './src/render';
import trigger from './src/trigger';
import { isAPIGte21 } from './src/utils';
import value from './src/value';
import video from './src/video';
export { useEventApi };
var eventApi = {
  audio: audio,
  auth: auth,
  chapter: chapter,
  clipboard: clipboard,
  document: document,
  fullScreen: fullScreen,
  qrScanner: qrScanner,
  video: video,
  render: render,
  isAPIGte21: isAPIGte21,
  once: once,
  trigger: trigger,
  listenOn: listenOn,
  value: value
};
export default eventApi;