import tinycolor from 'tinycolor2';
var DEFAULT_STOPS = [0, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 950, 1000];
var DEFAULT_STOP = 500;
var DEFAULT_PALETTE_CONFIG = {
  value: '#ffffff',
  valueStop: DEFAULT_STOP,
  swatches: [],
  h: 0,
  s: 0,
  lMin: 0,
  lMax: 100,
  useLightness: true
};
var clamp = function clamp(x, min, max) {
  return Math.min(Math.max(x, min), max);
};
var lightnessFromHSLum = function lightnessFromHSLum(h, s, l) {
  return tinycolor({
    h: h,
    s: s,
    l: l
  }).getLuminance();
};
var luminanceFromHex = function luminanceFromHex(color) {
  return tinycolor(color).getLuminance();
};
var unsignedModulo = function unsignedModulo(x, n) {
  return (x % n + n) % n;
};
var createSaturationScale = function createSaturationScale() {
  var tweak = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var stop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_STOP;
  var stops = DEFAULT_STOPS;
  var index = stops.indexOf(stop);
  if (index === -1) {
    throw new Error("Invalid key value: ".concat(stop));
  }
  return stops.map(function (stop) {
    var diff = Math.abs(stops.indexOf(stop) - index);
    var tweakValue = tweak ? Math.round((diff + 1) * tweak * (1 + diff / 10)) : 0;
    if (tweakValue > 100) {
      return {
        stop: stop,
        tweak: 100
      };
    }
    return {
      stop: stop,
      tweak: tweakValue
    };
  });
};
var createHueScale = function createHueScale() {
  var tweak = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var stop = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_STOP;
  var stops = DEFAULT_STOPS;
  var index = stops.indexOf(stop);
  if (index === -1) {
    throw new Error("Invalid parameter value: ".concat(stop));
  }
  return stops.map(function (stop) {
    var diff = Math.abs(stops.indexOf(stop) - index);
    var tweakValue = tweak ? diff * tweak : 0;
    return {
      stop: stop,
      tweak: tweakValue
    };
  });
};
var createDistributionValues = function createDistributionValues() {
  var min = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  var max = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 100;
  var lightness = arguments.length > 2 ? arguments[2] : undefined;
  var stop = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : DEFAULT_STOP;
  var stops = DEFAULT_STOPS;

  // Create known stops
  var newValues = [{
    stop: 0,
    tweak: max
  }, {
    stop: stop,
    tweak: lightness
  }, {
    stop: 1000,
    tweak: min
  }];

  // Create missing stops
  for (var _i = 0, _stops = stops; _i < _stops.length; _i++) {
    var stopValue = _stops[_i];
    if (stopValue === 0 || stopValue === 1000 || stopValue === stop) {
      continue;
    }
    var diff = Math.abs((stopValue - stop) / 100);
    var totalDiff = stopValue < stop ? Math.abs(stops.indexOf(stop) - stops.indexOf(DEFAULT_STOPS[0])) - 1 : Math.abs(stops.indexOf(stop) - stops.indexOf(DEFAULT_STOPS[DEFAULT_STOPS.length - 1])) - 1;
    var increment = stopValue < stop ? max - lightness : lightness - min;
    var tweak = stopValue < stop ? increment / totalDiff * diff + lightness : lightness - increment / totalDiff * diff;
    newValues.push({
      stop: stopValue,
      tweak: Math.round(tweak)
    });
  }
  newValues.sort(function (a, b) {
    return a.stop - b.stop;
  });
  return newValues;
};

/**
 * Based on [tints.dev](https://github.com/SimeonGriggs/tints.dev/blob/366e35a84950294da9556a288d471c0cf0bd4166/app/lib/createSwatches.ts)
 */
export var generatePalette = function generatePalette(palette) {
  var _palette$valueStop, _palette$useLightness, _palette$h, _palette$s, _palette$lMin, _palette$lMax;
  var value = palette.value;

  // Tweaks may be passed in, otherwise use defaults
  var valueStop = (_palette$valueStop = palette.valueStop) !== null && _palette$valueStop !== void 0 ? _palette$valueStop : DEFAULT_PALETTE_CONFIG.valueStop;
  var useLightness = (_palette$useLightness = palette.useLightness) !== null && _palette$useLightness !== void 0 ? _palette$useLightness : DEFAULT_PALETTE_CONFIG.useLightness;
  var h = (_palette$h = palette.h) !== null && _palette$h !== void 0 ? _palette$h : DEFAULT_PALETTE_CONFIG.h;
  var s = (_palette$s = palette.s) !== null && _palette$s !== void 0 ? _palette$s : DEFAULT_PALETTE_CONFIG.s;
  var lMin = (_palette$lMin = palette.lMin) !== null && _palette$lMin !== void 0 ? _palette$lMin : DEFAULT_PALETTE_CONFIG.lMin;
  var lMax = (_palette$lMax = palette.lMax) !== null && _palette$lMax !== void 0 ? _palette$lMax : DEFAULT_PALETTE_CONFIG.lMax;

  // Create hue and saturation scales based on tweaks
  var hueScale = createHueScale(h, valueStop);
  var saturationScale = createSaturationScale(s, valueStop);

  // Get the base hex's H/S/L values
  var _tinycolor$toHsl = tinycolor(value).toHsl(),
    valueH = _tinycolor$toHsl.h,
    valueS = _tinycolor$toHsl.s,
    valueL = _tinycolor$toHsl.l;

  // Create lightness scales based on tweak + lightness/luminance of current value
  var lightnessValue = useLightness ? valueL * 100 : luminanceFromHex(value);
  var distributionScale = createDistributionValues(lMin, lMax, lightnessValue, valueStop);
  var swatches = hueScale.map(function (_ref, stopIndex) {
    var stop = _ref.stop;
    var newH = unsignedModulo(valueH + hueScale[stopIndex].tweak, 360);
    var newS = clamp(valueS * 100 + saturationScale[stopIndex].tweak, 0, 100);
    var newL = useLightness ? distributionScale[stopIndex].tweak : lightnessFromHSLum(newH, newS, distributionScale[stopIndex].tweak);
    var newHex = tinycolor({
      h: newH,
      s: newS,
      l: newL
    }).toHexString();
    return {
      stop: stop,
      hex: stop === valueStop ? value : newHex,
      h: newH,
      s: newS,
      l: newL
    };
  });
  return swatches;
};