var serverStorage = {
  getItem: function getItem(key) {
    return key;
  },
  removeItem: function removeItem() {
    return null;
  },
  setItem: function setItem() {
    return null;
  },
  clear: function clear() {
    return null;
  },
  key: function key() {
    return null;
  },
  length: 0
};
var storage = true || false ? localStorage : serverStorage;
var get = function get(key) {
  if (key === 'Language') {
    return storage.getItem("X-User-".concat(key));
  }
  return storage.getItem("X-Mobile-".concat(key));
};
var getAll = function getAll() {
  var values = {};
  var keys = ['Access-Token', 'Audio-Version', 'Embedded', 'Event-Api-Version', 'Language', 'Organization', 'Refresh-Token', 'Token-Expires-On', 'URN-Scheme', 'Version'];
  keys.forEach(function (key) {
    var value = get(key);
    if (!value) return;
    values[key === 'Language' ? "X-User-".concat(key) : "X-Mobile-".concat(key)] = get(key);
  });
  return values;
};
export default {
  get: get,
  getAll: getAll
};