import http from './http';
import { getBaseUrl } from './utils';
import vault from './vault';
export var resetPassword = function resetPassword(password, requestCode, organization) {
  var baseUrl = getBaseUrl(organization);
  var data = {
    reset_password: {
      password: password,
      request_code: requestCode
    }
  };
  return http("".concat(baseUrl, "/api/reset_password"), data);
};
export var resetPasswordRequest = function resetPasswordRequest(username, phoneNumber, organization) {
  var baseUrl = getBaseUrl(organization);
  var identifierType = vault.getOrganization('identifierType', organization);
  var data = {
    reset_password_request: {
      identifier_type: identifierType,
      identifier_value: username,
      phone_number: phoneNumber,
      source: 'web'
    }
  };
  return http("".concat(baseUrl, "/api/reset_password_requests"), data);
};