import { useEffect } from 'react';
import trigger from './trigger';
export var useEventApi = function useEventApi(scope, command) {
  var params = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  var _ref = arguments.length > 3 ? arguments[3] : undefined,
    _ref$enable = _ref.enable,
    enable = _ref$enable === void 0 ? true : _ref$enable;
  useEffect(function () {
    if (!enable) return;
    trigger(scope, command, params);
  }, [scope, command, params, enable]);
};