/* eslint-disable global-require */

import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { initAmplitude } from '@/lib/amplitude-utils';
import initSentry from '@/lib/initSentry';
import { FALLBACK_LANG, useIntlSetup } from '@/lib/locale';
import AppInternalLayout from './AppInternalLayout';
import ReactQueryProvider from './ReactQueryProvider';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var RootLayout = function RootLayout(_ref) {
  var children = _ref.children;
  var _useIntlSetup = useIntlSetup(),
    locale = _useIntlSetup.locale,
    messages = _useIntlSetup.messages;
  useEffect(function () {
    initSentry();
    initAmplitude();
  }, []);
  if (!messages[locale]) {
    return null;
  }
  return _jsx(IntlProvider, {
    messages: messages[locale],
    locale: locale,
    defaultLocale: FALLBACK_LANG,
    children: _jsx(ReactQueryProvider, {
      children: _jsx(AppInternalLayout, {
        children: children
      })
    })
  });
};
export default RootLayout;