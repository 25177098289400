import auth from '@motimate/auth';
import eventApi from '@motimate/event-api';
import Router from 'next/router';
import UserAgent from '@/lib/user-agent';
var BASE_PATH = process.env.BASE_PATH;
function checkEnvVariable(envValue) {
  var envName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'BASE_PATH';
  if (!envValue) {
    throw new Error("Environment variable ".concat(envName, " not set"));
  }
  return envValue;
}
export var getIdentifier = function getIdentifier() {
  var _auth$getOrganization;
  var oldPaths = ['admin', 'contacts', 'discover', 'documents', 'editor', 'insights', 'password', 'profile', 'pulse', 'studio', 'tasks', 'today', 'training'];
  if (UserAgent.isMobileApp()) {
    return eventApi.value.get('Organization');
  }
  var organization = Router.query.organization;
  if (organization && typeof organization === 'string' && !oldPaths.includes(organization)) {
    return organization.toLocaleLowerCase();
  }
  return (_auth$getOrganization = auth.getOrganization()) !== null && _auth$getOrganization !== void 0 ? _auth$getOrganization : null;
};
export var isAppUrl = function isAppUrl(url) {
  return url.includes(checkEnvVariable(BASE_PATH));
};