import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import errorFactory from './errorFactory';
import vault from './vault';
export var setupOrganization = function setupOrganization(organization, config) {
  var _logo$url;
  vault.set('organization', organization);
  var baseUrl = config.baseUrl,
    externalSignoutUrl = config.externalSignoutUrl,
    externalVendor = config.externalVendor,
    identifierType = config.identifierType,
    logo = config.logo,
    mechanism = config.mechanism,
    name = config.name,
    oauthClientId = config.oauthClientId,
    oauthHost = config.oauthHost,
    oauthLoginUrl = config.oauthLoginUrl,
    oauthResource = config.oauthResource,
    oauthSignoutUrl = config.oauthSignoutUrl,
    oauthTokenEndpoint = config.oauthTokenEndpoint,
    oauthTokenResource = config.oauthTokenResource;
  if (mechanism === 'external_token') {
    console.warn(errorFactory('setupOrganization', 'mechanism `external_token` is deprecated - it continues as `internal`').message);
  }
  var configs = vault.get('configs');
  vault.set('configs', _objectSpread(_objectSpread({}, configs), {}, _defineProperty({}, organization, {
    baseUrl: baseUrl !== null && baseUrl !== void 0 ? baseUrl : null,
    externalSignoutUrl: externalSignoutUrl !== null && externalSignoutUrl !== void 0 ? externalSignoutUrl : null,
    externalVendor: externalVendor !== null && externalVendor !== void 0 ? externalVendor : null,
    identifierType: identifierType !== null && identifierType !== void 0 ? identifierType : 'email',
    logo: {
      url: (_logo$url = logo.url) !== null && _logo$url !== void 0 ? _logo$url : null
    },
    mechanism: mechanism,
    name: name,
    oauthClientId: oauthClientId !== null && oauthClientId !== void 0 ? oauthClientId : null,
    oauthHost: oauthHost !== null && oauthHost !== void 0 ? oauthHost : null,
    oauthLoginUrl: oauthLoginUrl !== null && oauthLoginUrl !== void 0 ? oauthLoginUrl : null,
    oauthResource: oauthResource !== null && oauthResource !== void 0 ? oauthResource : null,
    oauthSignoutUrl: oauthSignoutUrl !== null && oauthSignoutUrl !== void 0 ? oauthSignoutUrl : null,
    oauthTokenEndpoint: oauthTokenEndpoint !== null && oauthTokenEndpoint !== void 0 ? oauthTokenEndpoint : null,
    oauthTokenResource: oauthTokenResource !== null && oauthTokenResource !== void 0 ? oauthTokenResource : null
  })));
};
export var setupUser = function setupUser(user, organization) {
  vault.setUser('userId', user.id, organization);
};